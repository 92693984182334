export const waitFor = (predicate: () => boolean, timeout?: number) => {
  return new Promise((resolve, reject) => {
    let running = true;

    const check = async () => {
      const res = predicate();
      if (res) {
        return resolve(res);
      }
      if (running) {
        setTimeout(check, 100);
      }
    };

    check();

    if (!timeout) {
      return;
    }

    setTimeout(() => {
      running = false;
      reject(new Error('Function Timeout'));
    }, timeout);
  });
};
