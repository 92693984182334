export type SpacingSize =
  | 'none'
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '10'
  | '12'
  | '14'
  | '16'
  | '20'
  | '24';

export type SpacingType = {
  [key in SpacingSize]: string;
};

const spacing: SpacingType = {
  none: '0',
  '01': '0.25rem',
  '02': '0.5rem',
  '03': '0.75rem',
  '04': '1rem',
  '05': '1.25rem',
  '06': '1.5rem',
  '07': '1.75rem',
  '08': '2rem',
  '10': '2.5rem',
  '12': '3rem',
  '14': '3.5rem',
  '16': '4rem',
  '20': '5rem',
  '24': '6rem',
};

interface VideoSwiperDesign {
  thumbnailWidth: number;
  thumbnailWidthMedium: number;
  thumbnailGap: number;
  thumbnailGapMedium: number;
  aspectRatio: number;
}

const videoSwiperDesign: VideoSwiperDesign = {
  thumbnailWidth: 246, // px
  thumbnailWidthMedium: 266, // px
  thumbnailGap: parseInt(spacing['04']),
  thumbnailGapMedium: parseInt(spacing['04']),
  aspectRatio: 16 / 9,
};

export default {
  spacing,
  videoSwiperDesign,
};
