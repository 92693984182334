'use client';
import { createContext, useMemo, type ReactNode } from 'react';
import type { FeatureFlags } from 'api/getFeatureFlags';
import type { AdSizePredictionProps } from '../types/ads.d';
import type { BasisPageData, StructureTypes } from '../types/cms';
import type { TickarooPrefetchData } from '../types/storylines';
import type { EmbedTypes } from '../types/tracking';
import { disableDisplayAds, disableVideoAds } from '../utils/ads';
import { getMarketingDataByAttribute, getSectionDataByAttributeValue } from '../utils/areaSearch';
import { initErrorReporting } from '../utils/error';
import { getArticleMetaData, getVideoDetail } from './contextHelper';

export enum PageType {
  Overview,
  Article,
}

export interface AppContextState {
  pageType: StructureTypes;
  pageTitle?: string;
  rawPageData?: BasisPageData['areas'];
  adSizePrediction?: AdSizePredictionProps;
  adDebugMode?: boolean;
  analyticsData: AnalyticsDataProps;
  pageMetaData: PageMetaDataProps;
  prefetchedData?: TickarooPrefetchData;
  pushedAdSlots: Array<string>;
  requestPath?: string;
  featureFlags?: FeatureFlags;
}

interface PageMetaDataProps {
  adZone: string;
  noAds: boolean;
  noVideoAds?: boolean;
  overrideAdZone?: string;
  keywords?: Array<string>;
}

export interface AnalyticsDataArticleProps {
  id?: string;
  first_headline?: string;
  headline?: string;
  embeds?: Array<EmbedTypes>;
  tags?: Array<string>;
  first_publication_datetime?: string;
  last_update_datetime?: string;
  editorial_unit?: string;
}

interface AnalyticsDataProps {
  ivwCode: string;
  homeSectionPath?: string;
  articleFields?: AnalyticsDataArticleProps;
}

export const initialAppContext = {
  pageType: 'page' as StructureTypes,
  pageTitle: undefined,
  adSizePrediction: undefined,
  adDebugMode: process.env.NEXT_PUBLIC_ENABLE_AD_DEBUG_MODE == 'true',

  analyticsData: {
    ivwCode: 'rtl_portal/news',
    homeSectionPath: undefined,
    articleFields: undefined,
  },
  pageMetaData: {
    adZone: '_default',
    keywords: [],
    noAds: false,
    noVideoAds: false,
  },
  prefetchedData: undefined,
  pushedAdSlots: [],
};

interface AppProviderProps {
  children: ReactNode;
  value: Partial<AppContextState>;
}

export const AppContext = createContext<AppContextState>(initialAppContext);

export const AppProvider = ({ children, value }: AppProviderProps) => {
  if (typeof window !== 'undefined') {
    initErrorReporting();
  }

  const state = useMemo(
    () => ({
      ...initialAppContext,
      ...value,
    }),
    [value]
  );

  if (state.rawPageData?.meta) {
    state.pageMetaData.adZone = getMarketingDataByAttribute('ad.zone', value?.rawPageData?.meta) ?? '_default';

    state.pageMetaData.noAds = disableDisplayAds(value?.rawPageData?.meta);
    state.pageMetaData.noVideoAds = disableVideoAds(value?.rawPageData?.meta);

    const keywords = getSectionDataByAttributeValue('keywords', value?.rawPageData?.meta) ?? '';
    if (keywords) {
      state.pageMetaData.keywords = (keywords as string).split(',').map((k) => k.trim());
    }

    const analyticsData = value?.rawPageData?.meta?.filter((data) => data.template === 'analytics');
    if (analyticsData) {
      state.analyticsData.ivwCode = analyticsData[0]?.items[0]?.model?.tracking?.ivw ?? 'rtl_portal/news';
      state.analyticsData.homeSectionPath = analyticsData[0]?.items[0]?.model?.homeSection?.fullPath.replace(
        /(^\/|\/$)/g,
        ''
      );
    }
  }

  const videoMetaData = getVideoDetail(state.rawPageData?.main);
  if (videoMetaData) {
    state.analyticsData.articleFields = videoMetaData;
  } else {
    const articleMetaData = getArticleMetaData(state.rawPageData?.meta);
    if (articleMetaData) {
      state.analyticsData.articleFields = articleMetaData;
    }
  }

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};
