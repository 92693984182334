import { type RefObject, useEffect, useState } from 'react';

export function useIntersectionObserver(
  elementRef: RefObject<HTMLElement>,
  { threshold = 0, root = null, rootMargin = '0%' } = {}
) {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const updateEntry = ([entry]: Array<IntersectionObserverEntry>) => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;

    if (!window.IntersectionObserver || !node) {
      return;
    }

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin]);

  return entry;
}
