import type { PropsWithClassName } from '../../../types/react-props';
import { type IconNames, Icons } from './Icons';

export type IconProps = PropsWithClassName<{
  readonly name: IconNames;
  readonly size?: number;
  readonly title?: string;
  readonly height?: number;
  readonly viewBox?: string;
}>;

const Icon = ({ name, size = 20, title, className, height, viewBox = '0 0 20 20' }: IconProps) => (
  <svg
    className={className}
    viewBox={viewBox}
    width={size}
    height={height ?? size}
    fill="currentColor"
    focusable="false"
    role="img"
    aria-hidden="true"
  >
    {!!title && <title>{title}</title>}
    {Icons[name]}
  </svg>
);

export default Icon;
