import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import styles from './FallbackImage.module.scss';

type FallbackImageProps = PropsWithClassName<{}>;

const FallbackImage = ({ className }: FallbackImageProps) => (
  <svg className={clsx(styles.svg, className)} viewBox="0 0 800 450">
    <title>RTL</title>
    <g opacity="0.188">
      <path
        fill="#020203"
        d="M313.43 185v80.935H170V185h143.43zm-64.482 18.92H210.83v44.147h9.93v-15.451h19.938l17.565 15.45H272.6l-18.688-16.016c8.258-1.206 13.101-6.307 13.101-13.942 0-8.89-6.457-14.188-18.065-14.188zm-1.308 8.267c6.143 0 9.122 1.952 9.122 6.052s-2.979 6.118-9.122 6.118h-26.88v-12.17z"
      />
      <path
        fill="#6a6d71"
        d="M471.203 185v80.935h-143.43V185h143.43zm-40.83 18.92H369.65v8.701h25.357v35.446h10.01V212.62h25.355v-8.7z"
      />
      <path
        fill="#c0c2c6"
        d="M630 185v80.935H486.57V185H630zm-88.42 18.92h-9.992v44.147h54.44v-8.702h-44.447V203.92z"
      />
    </g>
  </svg>
);

export default FallbackImage;
