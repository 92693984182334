import type { CSSProperties } from 'react';
import theme, { type SpacingSize } from './themes';

export const spacing = (size: SpacingSize) => theme?.spacing?.[size];

export const responsiveStyle = (cssProperty: string, value: CSSStyleValue): CSSProperties => ({
  [`${cssProperty}`]: value,
});

export const responsiveSpacing = (cssProperty: string, value: SpacingSize = 'none') =>
  responsiveStyle(cssProperty, spacing(value));

const makeMultiSpacer = (values: Array<SpacingSize>) => values.map((v) => spacing(v)).join(' ');

export const responsiveMultiSpacing = (cssProperty: string, values: Array<SpacingSize> = ['none']) =>
  responsiveStyle(cssProperty, makeMultiSpacer(values));
