import { useEffect, useState } from 'react';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    setIsMobile(window.matchMedia(`(max-width: 768px)`).matches);
  }, []);

  return isMobile;
};
