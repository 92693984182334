export const Icons = {
  arrowLeft: <path d="M6.05 2.15h1.86l-5.24 7.1h17.08v1.5H2.67l5.24 7.1H6.05L.25 9.99l5.8-7.84Z" />,
  arrowRight: <path d="M13.95 2.15h-1.86l5.24 7.1H.25v1.5h17.08l-5.24 7.1h1.86l5.8-7.86-5.8-7.84Z" />,
  arrowRightUp: <path d="M4.295 1.25v1.523h11.856L1.25 17.674l1.076 1.076L17.227 3.849v11.856h1.523V1.25z" />,
  bubbles: (
    <path d="M3.369.389c-.371.067-.687.174-1.03.342C1.27 1.259.538 2.279.357 3.5.33 3.672.324 5.071.33 8.355l.01 4.613.074.274c.23.864.668 1.548 1.329 2.07.177.14.697.44.806.468.045.01.142.045.216.078s.242.084.371.116c.216.051.565.058 4.155.074l3.919.016 1.193 1.797c.658.987 1.21 1.796 1.226 1.796.019 0 .57-.81 1.225-1.796l1.194-1.797.129-.006a3.725 3.725 0 001.677-.48c.877-.51 1.458-1.291 1.732-2.336l.074-.274.01-4.613c.006-3.283 0-4.683-.026-4.854C19.402 1.885 18.224.67 16.622.39 16.373.347 15.56.34 9.968.343c-5.061.004-6.419.013-6.6.046zm12.688 4.183l.007.606H3.936v-.59c0-.326.01-.603.023-.613.01-.013 2.735-.02 6.054-.016l6.035.01.01.603zm0 2.419l.007.606H3.936v-.59c0-.326.01-.603.023-.613.01-.013 2.735-.019 6.054-.016l6.035.01.01.603zm-.045 1.82c.045.015.052.102.052.612v.593H3.936v-.593c0-.445.01-.6.042-.61.058-.025 11.973-.025 12.034-.003zM10 11.822v.613H3.936V11.21H10v.613z" />
  ),
  bullets: (
    <path d="M4.438 7.963C3.332 7.963 2.4 8.895 2.4 10a2.042 2.042 0 002.038 2.038A2.041 2.041 0 006.476 10a2.041 2.041 0 00-2.038-2.038zm11.124 0A2.042 2.042 0 0013.524 10a2.041 2.041 0 002.038 2.038A2.041 2.041 0 0017.6 10c0-1.106-.933-2.038-2.038-2.038zm-5.458 0A2.043 2.043 0 008.065 10a2.042 2.042 0 002.039 2.038A2.041 2.041 0 0012.142 10a2.041 2.041 0 00-2.038-2.038z" />
  ),
  caretSmallDown: (
    <path d="M15.302 6.24l-4.462 4.605-.84.875-.84-.875L4.696 6.24c-.378-.167-.578.724-.576 1.293v.002c.002.189.025.342.072.408l5.462 5.64c.19.264.503.264.692 0 1.478-1.526 2.587-2.67 3.326-3.435l2.135-2.205c.048-.066.072-.203.073-.373v-.01c.002-.516-.2-1.32-.578-1.32" />
  ),
  chevronAltDown: <path d="M0 6.108 2.357 3.75l7.646 7.782 7.64-7.782L20 6.108 10.003 16.25 0 6.108Z" />,
  chevronDoubleLeft: (
    <path d="m4.437 11.428 6.96 7.591c.338.858-2.173 1.182-2.573.858L.299 10.59c-.399-.325-.399-.856 0-1.18L8.824.124c.4-.326 2.573 0 2.573.859L4.438 8.571C3.778 9.29 3.114 10 3.114 10l1.323 1.428Zm8.571 0 6.961 7.591c.338.858-2.174 1.182-2.574.858L8.87 10.59c-.398-.325-.398-.856 0-1.18L17.395.124c.4-.326 2.574 0 2.574.859L13.01 8.571C12.349 9.29 11.686 10 11.686 10l1.322 1.428Z" />
  ),
  chevronDoubleRight: (
    <path d="M6.991 11.429.031 19.02c-.338.857 2.173 1.181 2.573.857l8.526-9.287c.399-.324.399-.856 0-1.18L2.604.124C2.204-.2.031.124.031.983c0 0 6.299 6.87 6.959 7.588C7.65 9.29 8.314 10 8.314 10l-1.323 1.429Zm8.572 0-6.96 7.591c-.339.857 2.173 1.181 2.573.857L19.7 10.59c.399-.324.399-.856 0-1.18L11.176.124c-.4-.325-2.573 0-2.573.859 0 0 6.298 6.87 6.958 7.588.66.719 1.325 1.429 1.325 1.429l-1.323 1.429Z" />
  ),
  chevronDown: (
    <path d="M20.0002 9.88012V8.00012L12.1402 14.0001L4.28015 8.00012L4.28015 9.88012L12.1402 15.8801L20.0002 9.88012Z" />
  ),
  chevronLeft: <path d="M12.091 2H14l-6.091 8L14 18h-1.909L6 10z" />,
  chevronRight: <path d="M7.909 2H6l6.091 8L6 18h1.909L14 10 7.909 2Z" />,
  chevronRightNew: <path d="M9.333 3.45H7.767l5 6.55-5 6.55h1.566l5-6.55-5-6.55Z" />,
  chevronUp: <path d="M2 12.091V14l8-6.091L18 14v-1.909L10 6l-8 6.091Z" />,
  clear: (
    <path d="M14.875 5.647l-.473-.522L10 9.507 5.607 5.125l-.473.522L9.51 10l-4.384 4.353.473.522L10 10.493l4.393 4.373.473-.513L10.49 10l4.384-4.353z" />
  ),
  clock: (
    <path d="M10 0c5.474 0 10 4.526 10 10s-4.526 10-10 10S0 15.474 0 10 4.526 0 10 0Zm0 1.46c-4.672 0-8.54 3.868-8.54 8.54 0 4.672 3.868 8.54 8.54 8.54 4.672 0 8.54-3.868 8.54-8.54 0-4.672-3.868-8.54-8.54-8.54Zm.292 3.43v4.818h3.796c.948 0 .948 1.46 0 1.46H9.635c-.438 0-.803-.292-.803-.73V4.891c0-.95 1.46-.95 1.46 0V4.89Z" />
  ),
  close: (
    <path d="M5.98636 5L5.00048 5.98587L11.0145 11.9998L5 18.0141L5.98588 19L12.0003 12.9856L18.0141 18.9993L19 18.0135L12.9862 11.9998L18.9995 5.98654L18.0136 5.00067L12.0003 11.0139L5.98636 5Z" />
  ),
  coupons: (
    <path d="M27.85 5.644a.646.646 0 00.195-.464V.69a.674.674 0 00-.684-.663h-6.878v.331a.672.672 0 01-.683.663.673.673 0 01-.684-.663V.027H.703A.674.674 0 00.02.69v4.49c0 .175.07.34.195.463l1.153 1.146a1.956 1.956 0 010 2.778L.215 10.711a.655.655 0 00-.195.463v4.491c0 .365.307.663.683.663h18.413v-.332c0-.366.306-.662.684-.662.379 0 .683.296.683.662v.332h6.878a.674.674 0 00.684-.663v-4.49a.646.646 0 00-.194-.463l-1.154-1.146a1.957 1.957 0 010-2.778l1.153-1.144zM3.66 5.253c0-1.323 1.11-2.399 2.473-2.399 1.364 0 2.473 1.076 2.473 2.399S7.497 7.652 6.133 7.652c-1.363 0-2.473-1.076-2.473-2.4zm1.817 7.977c-.238 0-.476-.08-.671-.243a.973.973 0 01-.101-1.402l7-7.818a1.046 1.046 0 011.445-.1c.428.36.473.988.102 1.403l-6.999 7.818a1.037 1.037 0 01-.776.342zm7.038-.205c-1.362 0-2.472-1.076-2.472-2.398 0-1.324 1.11-2.4 2.472-2.4 1.365 0 2.474 1.076 2.474 2.4 0 1.322-1.11 2.398-2.474 2.398zm7.968.738a.673.673 0 01-.683.664.675.675 0 01-.684-.664V11.53c0-.366.306-.662.684-.662.379 0 .683.296.683.662v2.234zm0-4.468a.672.672 0 01-.683.663.674.674 0 01-.684-.663V7.06c0-.366.306-.663.684-.663.379 0 .683.297.683.663v2.235zm0-4.468a.672.672 0 01-.683.662.674.674 0 01-.684-.662V2.593c0-.367.306-.663.684-.663.379 0 .683.296.683.663v2.234z" />
  ),
  cross: (
    <path
      d="M14 1.50044L12.7143 0L7.04177 5.53575L1.45311 0L0 1.57337L5.51883 7.05085L0.0696127 12.4266L1.52269 14L7.04177 8.55137L12.5469 14L14 12.4266L8.5647 7.05085L14 1.50044Z"
      fill="#F7F8F9"
    />
  ),
  expertTest: (
    <path d="M18.467.85v14.116l-9.234 4.91L0 14.966V.849h18.467zM13.85 11.282H3.298v2.455H13.85v-2.455zM9.563 8.214H3.298v2.455h6.265V8.214zm4.287-3.07H3.298V7.6H13.85V5.145z" />
  ),
  externalLink: (
    <path d="M9.166 5H2.5v12.5H15V10h-1.25v6.25h-10v-10h5.416V5Zm5.758-1.25-6.42 6.42 1.325 1.326 6.421-6.421v4.091h1.25V2.5h-6.666v1.25h4.09Z" />
  ),
  fullscreenEnter: (
    <path d="M1.7 7.8H0V1h6.8v1.7H1.7v5.1zm11.9-5.1V1h6.8v6.8h-1.7V2.7h-5.1zM1.7 11.2H0V18h6.8v-1.7H1.7v-5.1zm11.9 5.1h5.1v-5.1h1.7V18h-6.8v-1.7z" />
  ),
  fullscreenExit: (
    <path d="M1 6.327h4.327V2h2.02v6.346H1v-2.02zm4.327 6.346H1v-2.02h6.346V17h-2.02v-4.327zm9.23-6.346V2h-2.019v6.346h6.347v-2.02h-4.327zm0 10.673h-2.019v-6.346h6.347v2.02h-4.327V17z" />
  ),
  gallery: <path d="M14.167 16.666v-10H2.5v10h11.667ZM17.5 12.5V3.333H6.667v1.25h9.583V12.5h1.25Z" />,
  information: (
    <path d="M9.97 0C4.5 0 0 4.5 0 9.97S4.5 20 9.97 20 20 15.5 20 9.97 15.5 0 9.97 0Zm.43 3.85c.92 0 1.63.76 1.63 1.63-.05.92-.76 1.63-1.63 1.63-.92 0-1.63-.76-1.63-1.63 0-.92.76-1.63 1.63-1.63Zm2.33 10.95c-.49 1.79-5.26 1.95-4.93-.49.22-1.63.54-3.2.81-4.77.16-1.03-.49-.7-1.08-.92-.38-.16-.38-.65.11-.76.6-.16 2.71-.05 3.41-.05.38 0 .6.22.65.6 0 .33-.05.6-.11.92-.27 1.52-.65 3.14-.87 4.66 0 .33.05.92.54.87.49 0 .7-.22 1.08-.38.22-.11.43.05.38.33Z" />
  ),
  pause: <path d="M8.802 2H4v16h4.802V2ZM16 2h-4.802v16H16V2Z" />,
  piggyBank: (
    <path d="M11.86 4.322c-1.228 0-2.224-.959-2.224-2.14 0-1.182.996-2.14 2.223-2.14 1.228 0 2.224.958 2.224 2.14 0 1.181-.996 2.14-2.224 2.14m-6.67 7.313c-.513 0-.927-.4-.927-.892s.414-.892.926-.892c.513 0 .927.4.927.892 0 .493-.414.892-.927.892m16.95-.89c-.067 0-.129.01-.193.019-1.064-3.286-5.016-5.729-9.743-5.729-2.759 0-4.64.678-6.004 1.565-1.102-.641-2.463-1.038-2.99-.761-.173.09-.394.223-.1.845.2.422.6 1.338.75 2.065-.916 1.088-1.5 1.994-2.308 1.994H.994a.992.992 0 00-.994.992v1.71c0 1.423 0 1.611 1.16 2.507a20.778 20.778 0 003.836 2.37l1.102 3.182c.136.39.516.655.944.655h.88a.99.99 0 00.965-.723l.452-1.715c1.018.187 2.101.297 3.262.297.67 0 1.326-.057 1.963-.164l.416 1.582a.99.99 0 00.965.723h.88a.994.994 0 00.944-.655l1.171-3.382c1.615-1.109 2.762-2.623 3.146-4.34.017.001.035.005.052.005.873 0 1.58-.681 1.58-1.521 0-.841-.707-1.521-1.58-1.521" />
  ),
  play: <path d="M4 2.5 16 10 4 17.5v-15Z" />,
  playOutline: (
    <path d="M 2.3032495,4.0972497 11.951027,10.034793 2.304084,15.972336 C 2.3032078,12.013974 2.3056946,8.0556116 2.3032495,4.0972497 Z M 0.41725549,0.74668937 V 19.322896 L 15.511049,10.034793 Z" />
  ),
  playlist: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.23648 3.2728L0 0V6.5456L5.23648 3.2728Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3033 1.92012V0.120117H7.5001V1.92012H15.3033ZM15.1533 0.270117H7.6501L15.1501 0.270508V1.77012H15.1533V0.270117Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3033 6.42012V4.62012H7.5001V6.42012H15.3033ZM15.1533 4.77012H7.6501L15.1501 4.77051V6.27012H15.1533V4.77012Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3033 10.9201V9.12012H0.100195V10.9201H15.3033ZM15.1533 9.27012H0.250195L15.1501 9.27051V10.7701H15.1533V9.27012Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3033 15.4201V13.6201H0.100195V15.4201H15.3033ZM15.1533 13.7701H0.250195L15.1501 13.7705V15.2701H15.1533V13.7701Z"
        fill="white"
      />
    </svg>
  ),
  repeatVideo: (
    <path d="M15 14.94H9.92l2.17 2.95h-1.77l-2.7-3.665 2.7-3.66h1.77l-2.17 2.95H15a3.59 3.59 0 0 0 3.386-3.57A3.59 3.59 0 0 0 15 6.375v-1.43c2.682.085 4.842 2.314 4.842 4.997 0 2.684-2.16 4.913-4.842 4.998ZM5 4.945h5.085L7.915 2h1.77l2.7 3.665-2.7 3.655h-1.77l2.17-2.95H5a3.59 3.59 0 0 0-3.386 3.57A3.59 3.59 0 0 0 5 13.51v1.43C2.318 14.855.158 12.626.158 9.942.158 7.26 2.318 5.03 5 4.945Z" />
  ),
  search: (
    <path d="M12.731 12.403c.807-1.092 1.311-2.42 1.311-3.882C14.042 4.924 11.118 2 7.521 2S1 4.924 1 8.521s2.924 6.521 6.521 6.521c1.462 0 2.79-.504 3.882-1.31l4.925 4.923H19l-6.269-6.252zm-5.21.757c-2.555 0-4.622-2.084-4.622-4.622s2.067-4.622 4.622-4.622a4.619 4.619 0 014.622 4.622 4.62 4.62 0 01-4.622 4.622z" />
  ),
  seekBackward15: (
    <path d="M3.428 2.61A9.733 9.733 0 0 1 10.044 0c5.355 0 9.712 4.357 9.712 9.713 0 5.355-4.357 9.712-9.712 9.712a9.697 9.697 0 0 1-6.478-2.475l.951-1.062A8.27 8.27 0 0 0 10.044 18c4.569 0 8.287-3.718 8.287-8.287 0-4.57-3.718-8.288-8.287-8.288a8.291 8.291 0 0 0-5.773 2.356l2.603.003-.002 1.425L2 5.203 2.005.331 3.43.333 3.428 2.61Zm10.774 8.484c0 1.268-.774 2.204-2.232 2.204-1.331 0-2.205-.854-2.205-1.908h1.08c.027.495.387.955 1.125.955.819 0 1.143-.595 1.143-1.234 0-.611-.342-1.17-1.143-1.17-.495 0-.81.216-1.044.531H9.864l.243-3.572h3.708v.962h-2.808l-.126 1.603h.036c.261-.244.666-.423 1.224-.423 1.269 0 2.061.882 2.061 2.052ZM8.611 6.9v6.3h-1.08V8.025h-.036L6.046 9.131h-.09V8.079L7.522 6.9h1.089Z" />
  ),
  seekForward15: (
    <path d="M1.425 9.713C1.425 14.282 5.143 18 9.713 18a8.27 8.27 0 0 0 5.525-2.111l.951 1.061a9.694 9.694 0 0 1-6.476 2.475C4.357 19.425 0 15.068 0 9.713 0 4.357 4.357 0 9.713 0c2.462 0 4.825.945 6.614 2.61L16.325.333 17.75.331l.005 4.872-4.871.006-.002-1.425 2.603-.003a8.29 8.29 0 0 0-5.772-2.356c-4.57 0-8.288 3.718-8.288 8.288Zm12.091 1.364c0 1.269-.774 2.205-2.231 2.205-1.333 0-2.206-.854-2.206-1.907h1.08c.027.495.387.953 1.125.953.819 0 1.143-.593 1.143-1.233 0-.612-.342-1.17-1.143-1.17-.495 0-.81.216-1.044.531H9.178l.243-3.572h3.708v.962h-2.808l-.126 1.602h.036c.261-.243.666-.423 1.224-.423 1.269 0 2.061.882 2.061 2.052ZM7.925 6.883v6.3h-1.08V8.008h-.036L5.36 9.115h-.09V8.062l1.566-1.179h1.089Z" />
  ),
  speaker: (
    <path d="M0 10a3.588 3.588 0 0 1 3.571-3.571h2.143l5.714-4.286v15.714l-5.714-4.286H3.571A3.588 3.588 0 0 1 0 10Zm17.952-5.835L16.93 5.187a7.83 7.83 0 0 1-.338 9.983l.925 1.11A9.244 9.244 0 0 0 20 9.973c0-2.2-.77-4.217-2.048-5.81v.002Zm-4.115 4.113a3.52 3.52 0 0 1 .448 1.695 3.53 3.53 0 0 1-.512 1.815l.953 1.143c.613-.83.988-1.846.988-2.958a4.97 4.97 0 0 0-.827-2.744l-1.05 1.05v-.001Z" />
  ),
  speakerMuted: (
    <path d="M0 10a3.51 3.51 0 0 1 3.492-3.493l2.095.001 5.587-4.19v15.365l-5.587-4.19H3.492A3.509 3.509 0 0 1 0 10.001v-.002V10Zm19.11-3.586.89.889L17.301 10 20 12.698l-.89.89-2.698-2.699-2.698 2.698-.89-.889 2.7-2.698-2.7-2.698.89-.89 2.698 2.699 2.698-2.697Z" />
  ),
  topics: (
    <path
      d="M2.093 4.707V4.7c-.98 0-1.786.806-1.786 1.786L.236 17.87c0 .981.806 1.787 1.785 1.787h15.836c.98 0 1.786-.806 1.786-1.786l-.072-11.388a1.793 1.793 0 0 0-1.785-1.776H2.093Zm4.693.714H2.093l-.007-.007a1.077 1.077 0 0 0-1.065 1.073L.95 17.872c0 .587.484 1.071 1.071 1.071h15.836c.588 0 1.071-.484 1.072-1.071l-.072-11.385a1.077 1.077 0 0 0-1.071-1.066H12.5v.65H6.786v-.65ZM3.214 3.214h13.572V2.5H3.214v.714Zm1.429-2.143h10.714V.357H4.643v.714Z"
      fillRule="evenodd"
    />
  ),
  amazonLogo: (
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Amazon-Logo-Orange-Pfeil" transform="translate(-133.000000, -100.000000)">
        <g transform="translate(133.000000, 100.000000)">
          <path
            d="M37.190632,14.1294424 C33.7075093,16.7000743 28.6579182,18.0669145 24.3095911,18.0669145 C18.2154647,18.0669145 12.7275836,15.8141264 8.57486989,12.064684 C8.24877323,11.7698141 8.5394052,11.3681041 8.93152416,11.5958364 C13.412119,14.2030483 18.9528625,15.7730855 24.6756134,15.7730855 C28.5359108,15.7730855 32.7794052,14.972119 36.6843123,13.3157621 C37.2731599,13.066171 37.7669888,13.7036431 37.190632,14.1294424"
            id="Fill-1"
            fill="#EC9636"
          />
          <path
            d="M38.64,12.4746468 C38.1945725,11.9045353 35.695316,12.2047584 34.5722677,12.3390335 C34.232342,12.3800743 34.1797026,12.0827509 34.486171,11.8675093 C36.48,10.4669888 39.7469888,10.8713755 40.1266171,11.3404461 C40.5089219,11.8133086 40.0260223,15.0885502 38.1577695,16.6516729 C37.8702602,16.8918959 37.5965799,16.7636431 37.7243866,16.4462454 C38.1448327,15.3963569 39.0858736,13.0456506 38.64,12.4746468"
            id="Fill-2"
            fill="#EC9636"
          />
          <path
            d="M34.6514498,1.97464684 L34.6514498,0.614052045 C34.6527881,0.4066171 34.8084758,0.268996283 34.9965056,0.269665428 L41.095316,0.268996283 C41.2904833,0.268996283 41.4470632,0.410855019 41.4470632,0.612267658 L41.4470632,1.77925651 C41.4450558,1.97509294 41.280223,2.23026022 40.9878067,2.63598513 L37.8285502,7.14624535 C39.0011152,7.11881041 40.241487,7.29479554 41.3072119,7.89345725 C41.5474349,8.02840149 41.6118959,8.22825279 41.630632,8.42453532 L41.630632,9.87657993 C41.630632,10.0764312 41.4115985,10.3081784 41.1814126,10.1875093 C39.304461,9.20408922 36.8134572,9.09680297 34.7377695,10.1995539 C34.5254275,10.3124164 34.3037175,10.0842379 34.3037175,9.88371747 L34.3037175,8.50371747 C34.3037175,8.28289963 34.3079554,7.90527881 34.5307807,7.56869888 L38.1907807,2.31769517 L35.0038662,2.31747212 C34.809145,2.31747212 34.6527881,2.17851301 34.6514498,1.97464684"
            id="Fill-3"
            fill="#FFFFFF"
          />
          <g id="Group-8" fill="#FFFFFF">
            <path
              d="M46.6973978,0.0936802974 C49.4509294,0.0936802974 50.940223,2.45843866 50.940223,5.46401487 C50.940223,8.36921933 49.2954647,10.6742007 46.6973978,10.6742007 C43.9953903,10.6742007 42.5228253,8.30921933 42.5228253,5.36386617 C42.5228253,2.39799257 44.012342,0.0936802974 46.6973978,0.0936802974 Z M46.7141264,2.03754647 C45.3457249,2.03754647 45.2593961,3.90066914 45.2593961,5.06319703 C45.2593961,6.22527881 45.2422305,8.71003717 46.6973978,8.71003717 C48.1349442,8.71003717 48.2038662,6.706171 48.2038662,5.48431227 C48.2038662,4.68289963 48.1692937,3.72089219 47.9263941,2.95918216 C47.7187361,2.29784387 47.302974,2.03754647 46.7141264,2.03754647 L46.7141264,2.03754647 Z"
              id="Fill-4"
            />
            <path
              d="M29.7901115,5.92594796 C29.7901115,6.64929368 29.8079554,7.25130112 29.4432714,7.89434944 C29.1484015,8.41605948 28.6795539,8.73769517 28.1589591,8.73769517 C27.4474349,8.73769517 27.0307807,8.19524164 27.0307807,7.39182156 C27.0307807,5.81152416 28.4484758,5.52446097 29.7901115,5.52446097 L29.7901115,5.92594796 Z M31.6610409,10.4495911 C31.5383643,10.559777 31.3142007,10.5635688 31.2227509,10.4928625 C30.5888476,10.0023792 30.1592565,9.25650558 30.1592565,9.25650558 C29.1405948,10.2939033 28.4192565,10.6055019 27.1001487,10.6055019 C25.5381413,10.6055019 24.323197,9.64104089 24.323197,7.71345725 C24.323197,6.2072119 25.1386617,5.18297398 26.3018587,4.68111524 C27.3082528,4.23903346 28.7145725,4.15873606 29.7901115,4.03806691 C29.7901115,4.03806691 29.880223,2.87553903 29.5648327,2.45174721 C29.3205948,2.12319703 28.9050558,1.96951673 28.5236431,1.96951673 C27.835316,1.96684015 27.1704089,2.34111524 27.0383643,3.06111524 C27.0006691,3.26698885 26.8485502,3.44007435 26.6884015,3.42267658 L24.9115985,3.23018587 C24.775539,3.21434944 24.590855,3.07405204 24.6345725,2.84208178 C25.047881,0.660669145 27.0178439,0.00156133829 28.7837175,0.00156133829 C29.6863941,0.00156133829 30.8665428,0.242230483 31.57829,0.925427509 C32.4805204,1.76877323 32.3942007,2.89360595 32.3942007,4.11881041 L32.3942007,7.00907063 C32.3942007,7.87895911 32.7550929,8.2601487 33.0939033,8.72832714 C33.212342,8.89762082 33.2393309,9.09814126 33.0883271,9.22148699 C32.709145,9.53933086 31.6610409,10.4495911 31.6610409,10.4495911 L31.6610409,10.4495911 Z"
              id="Fill-6"
            />
            <path
              d="M5.46713755,5.92594796 C5.46713755,6.64929368 5.48453532,7.25130112 5.12007435,7.89434944 C4.82520446,8.41605948 4.35635688,8.73769517 3.83576208,8.73769517 C3.12423792,8.73769517 2.70758364,8.19524164 2.70758364,7.39182156 C2.70758364,5.81152416 4.12527881,5.52446097 5.46713755,5.52446097 L5.46713755,5.92594796 Z M7.33784387,10.4495911 C7.21516729,10.559777 6.99100372,10.5635688 6.8995539,10.4928625 C6.26565056,10.0023792 5.83605948,9.25650558 5.83605948,9.25650558 C4.81717472,10.2939033 4.09605948,10.6055019 2.77717472,10.6055019 C1.21494424,10.6055019 0,9.64104089 0,7.71345725 C0,6.2072119 0.815464684,5.18297398 1.97866171,4.68111524 C2.98505576,4.23903346 4.39137546,4.15873606 5.46713755,4.03806691 C5.46713755,4.03806691 5.55680297,2.87553903 5.24163569,2.45174721 C4.99739777,2.12319703 4.58185874,1.96951673 4.20022305,1.96951673 C3.51211896,1.96684015 2.8472119,2.34111524 2.71516729,3.06111524 C2.67747212,3.26698885 2.52557621,3.44007435 2.36542751,3.42267658 L0.588401487,3.23018587 C0.452342007,3.21434944 0.267657993,3.07405204 0.311375465,2.84208178 C0.724460967,0.660669145 2.69464684,0.00156133829 4.46052045,0.00156133829 C5.36319703,0.00156133829 6.54334572,0.242230483 7.25509294,0.925427509 C8.15732342,1.76877323 8.07078067,2.89360595 8.07078067,4.11881041 L8.07078067,7.00907063 C8.07078067,7.87895911 8.43189591,8.2601487 8.77092937,8.72832714 C8.88914498,8.89762082 8.91635688,9.09814126 8.76513011,9.22148699 C8.386171,9.53933086 7.33784387,10.4495911 7.33784387,10.4495911 L7.33784387,10.4495911 Z"
              id="Fill-7"
            />
          </g>
          <path
            d="M12.7639405,5.08304833 C12.7639405,3.98096654 12.7117472,2.45843866 14.0455762,2.45843866 C15.3613383,2.45843866 15.1884758,4.02111524 15.1884758,5.08304833 L15.1902602,10.1317472 C15.1902602,10.3142007 15.337026,10.4616357 15.5232714,10.4734572 L17.376803,10.4734572 C17.5742007,10.4734572 17.7343494,10.3206691 17.7343494,10.130855 L17.7343494,5.08304833 C17.7343494,4.54193309 17.7171747,3.74074349 17.9074349,3.25962825 C18.0981413,2.77873606 18.5658736,2.47806691 19.0162082,2.47806691 C19.5528625,2.47806691 19.9686245,2.65851301 20.1071375,3.3 C20.1936803,3.68074349 20.1591078,4.68289963 20.1591078,5.08304833 L20.159777,10.1317472 C20.159777,10.3142007 20.3063197,10.4616357 20.4925651,10.4734572 L22.3456506,10.4734572 C22.5437175,10.4734572 22.7036431,10.3206691 22.7036431,10.130855 L22.7054275,4.12148699 C22.7054275,3.09925651 22.8263197,1.93739777 22.2374721,1.13553903 C21.7179926,0.414200743 20.8692937,0.0936802974 20.0899628,0.0936802974 C18.9988104,0.0936802974 17.977026,0.654869888 17.5264684,1.85710037 C17.0069888,0.654869888 16.2794052,0.0936802974 15.1191078,0.0936802974 C13.9762082,0.0936802974 13.1275093,0.654869888 12.6773978,1.85710037 L12.6423792,1.85710037 L12.6423792,0.641040892 C12.6423792,0.449665428 12.4871375,0.294423792 12.2957621,0.294423792 L10.564461,0.294423792 C10.3730855,0.294423792 10.2178439,0.449665428 10.2178439,0.641040892 L10.2178439,10.1268401 C10.2178439,10.3182156 10.3730855,10.4734572 10.564461,10.4734572 L12.4171004,10.4734572 C12.6084758,10.4734572 12.7637175,10.3182156 12.7637175,10.1268401 L12.7639405,5.08304833"
            id="Fill-9"
            fill="#FFFFFF"
          />
          <path
            d="M54.8736803,10.1268401 C54.8736803,10.3182156 54.7186617,10.4734572 54.5272862,10.4734572 L52.6744238,10.4734572 C52.4830483,10.4734572 52.3278067,10.3182156 52.3278067,10.1268401 L52.3278067,0.641040892 C52.3278067,0.449665428 52.4830483,0.294423792 52.6744238,0.294423792 L54.3874349,0.294423792 C54.5881784,0.294423792 54.734052,0.455910781 54.734052,0.565204461 L54.734052,2.01747212 L54.7699628,2.01747212 C55.2896654,0.714423792 56.0165799,0.0936802974 57.2982156,0.0936802974 C58.1297398,0.0936802974 58.9438662,0.393903346 59.4635688,1.21583643 C59.9482528,1.97710037 59.9482528,3.25962825 59.9482528,4.18148699 L59.9482528,10.1739033 C59.9277323,10.3423048 59.7760595,10.4734572 59.5927138,10.4734572 L57.7329368,10.4734572 C57.5607435,10.4625279 57.4222305,10.3362825 57.4023792,10.1739033 L57.4055019,4.4197026 C57.4055019,2.49479554 56.667881,2.43814126 56.2423048,2.43814126 C55.7471375,2.43814126 55.3487732,2.82468401 55.1745725,3.18557621 C54.9149442,3.76684015 54.8734572,4.36171004 54.8734572,5.00342007 L54.8736803,10.1268401"
            id="Fill-10"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  ),
};

export type IconNames = keyof typeof Icons;
