import type { MouseEvent } from 'react';
import { clsx } from 'clsx';
import type { PropsWithClassName } from '../../../types/react-props';
import styles from './BurgerMenu.module.scss';

type BurgerMenuButtonProps = PropsWithClassName<{
  isActive: boolean;
  onClick?: (event: MouseEvent) => void;
}>;

const BurgerMenu = ({ isActive, onClick, className }: BurgerMenuButtonProps) => (
  <button aria-label="Menu" onClick={onClick} className={clsx(styles.button, className)}>
    <span className={clsx(styles.inner, isActive && styles.active)}></span>
  </button>
);

export default BurgerMenu;
