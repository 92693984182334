import type {
  TcfApiCallback,
  UserConsent,
  CustomVendorConsentsResult,
  CustomVendor,
  TcData,
} from '../../../types/consent';
import { isBrowser } from '../../../utils/detect';

const TCF_VERSION = 2;
const TCF_DIALOG_ID = 168480;

/**
 * TCF/Sourcepoint IDs for non-IAB vendors
 *
 * @see https://analytics.sourcepoint.com/consent_v2/vendor_lists
 */
export const TCF_VENDOR_IDS: { [key: string]: string } = {
  FACEBOOK: '5ee91b9593fc094b59242e25',
  TWITTER: '5e71760b69966540e4554f01',
  INSTAGRAM: '5e717c8e69966540e4554f05',
  YOUTUBE: '5e7ac3fae30e7d1bc1ebf5e8',
  NIELSEN: '5f9be0a9a228636148510755',
  INF_ONLINE: '5efefe25b8e05c065164a2e2',
  GOOGLE_ANALYTICS: '5e542b3a4cd8884eb41b5a72',
};

const createUserConsent = (
  tcString: string,
  { consentedPurposes, consentedVendors, grants }: CustomVendorConsentsResult
): UserConsent => ({
  tcString,
  consentedPurposes,
  consentedVendors,
  grants,
});

const makeConsentUpdateHandler = (callback: (consent: UserConsent) => void) => {
  const baseHandler: TcfApiCallback = (tcData: TcData, success: boolean) => {
    baseHandler.listenerId = tcData.listenerId;
    if (
      success &&
      (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete') &&
      window.__tcfapi
    ) {
      window.__tcfapi(
        'getCustomVendorConsents',
        TCF_VERSION,
        (vendorConsents: CustomVendorConsentsResult, getCustomVendorSuccess: boolean) => {
          if (getCustomVendorSuccess) {
            callback(createUserConsent(tcData.tcString, vendorConsents));
          }
        }
      );
    }
  };

  return baseHandler;
};

export const setCustomConsentForProvider = (vendorId: string, callback: (data: Array<CustomVendor>) => void): void => {
  if (window.__tcfapi) {
    window.__tcfapi(
      'postCustomConsent',
      TCF_VERSION,
      (data: TcData, success: boolean) => {
        if (success) {
          callback(data.customVendorsResponse.consentedVendors);
        }
      },
      [vendorId],
      [],
      []
    );
  }
};

export const listenForConsent = (callback: (data: UserConsent) => void) => {
  if (isBrowser()) {
    const handler = makeConsentUpdateHandler(callback);

    if (window.__tcfapi) {
      window.__tcfapi('addEventListener', TCF_VERSION, handler);
    }

    return () => {
      if (window.__tcfapi && Number.isInteger(handler.listenerId)) {
        window.__tcfapi('removeEventListener', TCF_VERSION, handler, handler.listenerId);
      }
    };
  }
};

/**
 * Export link handlers to manually open the Privacy Manager
 */
export const openPrivacyCenter = () =>
  window._sp_?.loadPrivacyManagerModal ? window._sp_.loadPrivacyManagerModal(TCF_DIALOG_ID) : null;
