'use client';
import Script from 'next/script';
import { iab /* , iab2 */ } from './iab';

const ACCOUNT_ID = 1239;

/**
 * Sourcepoint consent management integration
 */
export const Sourcepoint = () => (
  <>
    <Script id="iab-script" dangerouslySetInnerHTML={{ __html: iab }} />
    <Script
      id="sourcepoint-configuration"
      dangerouslySetInnerHTML={{
        __html: `
          if (navigator.cookieEnabled) {
            // enable SP only when cookies are enabled (this condition is used to hide the SP layer for bots)
            window._sp_ = {
              config: {
                accountId: ${ACCOUNT_ID},
                baseEndpoint: 'https://sourcepoint.rtl.de',
                propertyHref: 'https://rtl.de/',
                joinHref: true,
                events: {
                  onPrivacyManagerAction() {
                   setTimeout(function () { window.location.reload() }, 500);
                  }
                }
              }
            };
          }
        `,
      }}
    />
    <Script src="https://sourcepoint.rtl.de/wrapperMessagingWithoutDetection.js" />
    {/* CONFIG FOR NEW SOURCEPOINT VERSION

    <Script id="iab-script-2" dangerouslySetInnerHTML={{ __html: iab2 }} />
    <Script
      id="sourcepoint-configuration"
      dangerouslySetInnerHTML={{
        __html: `
          if (navigator.cookieEnabled) {
            // enable SP only when cookies are enabled (this condition is used to hide the SP layer for bots)
            window._sp_queue = []
            window._sp_ = {
              config: {
                accountId: ${ACCOUNT_ID},
                baseEndpoint: 'https://cdn.privacy-mgmt.com',
                propertyHref: 'https://rtl.de/',
                joinHref: true,
                ccpa: { },
                gdpr: { },
                events: {
                  onPrivacyManagerAction: function() {
                      console.log('[event] onPrivacyManagerAction', arguments);
                      setTimeout(function () { window.location.reload() }, 500);
                  },
                }
              }
            };
          }
        `,
      }}
    />
    <Script src="https://cdn.privacy-mgmt.com/unified/wrapperMessagingWithoutDetection.js" /> */}
  </>
);
