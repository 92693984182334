import { getSeoTitle } from '../components/UI/SchemaObjects/helper/utils';
import type { VideoTags } from '../components/UI/VideoPlayer/VideoPlayerDefault';
import { type AreaItem, type BasisPageData } from '../types/cms';
import { getArticleHeadDataByAttribute, getTrackingDataByAttribute } from '../utils/areaSearch';

export const getArticleMetaData = (context?: Array<AreaItem>) => {
  if (!context) {
    return;
  }

  const articleDetails = context?.find((widget) => widget.template === 'article-detail' && widget.view === 'head');
  const firstPublishedDate = getTrackingDataByAttribute('firstPublishedDate', context);

  return {
    id: getTrackingDataByAttribute('id', context),
    first_headline: getArticleHeadDataByAttribute('first-published-title', context) ?? '',
    headline:
      getArticleHeadDataByAttribute('seo-title', context) ?? getArticleHeadDataByAttribute('title', context) ?? '',
    tags: getTrackingDataByAttribute('tags', context) ?? [],
    first_publication_datetime: firstPublishedDate
      ? `${getTrackingDataByAttribute('firstPublishedDate', context)}`
      : '',
    last_update_datetime: articleDetails?.items[0]?.model.lastmodifiedAt
      ? `${articleDetails?.items[0]?.model.lastmodifiedAt}`
      : '',
    editorial_unit: getArticleHeadDataByAttribute('ressort', context) ?? '',
  };
};

export const getVideoDetail = (context: BasisPageData['areas']['main']) => {
  const videoDetails = context?.find((widget) => widget.template === 'video' && widget.view === 'detail');

  if (videoDetails?.items?.length > 0) {
    const item = videoDetails.items[0];
    const fields = item.model.fields;

    return {
      id: item.model.id,
      first_headline: getSeoTitle(fields['first-title'], fields['first-subtitle']),
      headline: getSeoTitle(fields.title, fields.subtitle),
      tags: item.model.tags ? item.model.tags.map((item: VideoTags) => item.name).join(', ') : '',
      first_publication_datetime: item.model.publishedAt ?? '',
      last_update_datetime: item.model.lastmodifiedAt ?? '',
    };
  }
};
