import { clsx } from 'clsx';
import styles from './VideoPlayerControlsLoading.module.scss';

export const VideoPlayerControlsLoading = ({ className = '' }: { className?: string }) => (
  <div className={clsx(styles.root, className)}>
    <div className={clsx(styles.box, styles.box1)} />
    <div className={clsx(styles.box, styles.box2)} />
    <div className={clsx(styles.box, styles.box3)} />
  </div>
);
