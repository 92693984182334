import { v4 as uuidv4 } from 'uuid';

const map = new WeakMap();
let counter = 0;

export const getKey = (key: string | undefined, obj?: object): string | undefined => {
  if (key) {
    return key;
  }

  if (obj) {
    return generateWeakKey(obj);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateWeakKey = (obj: any, prefix: string = 'weak-key') => {
  let key = map.get(obj);
  if (!key) {
    key = `${prefix}-${counter++}`;
    map.set(obj, key);
  }
  return key;
};

export const UUID = (): string => uuidv4();
