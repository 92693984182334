import { useEffect, useRef, useState, type MutableRefObject } from 'react';
import type CbcVideoplayer from '@cbc/videoplayer/src/cbcVideoplayer';
import { getPlayerEventsCallback } from './functions';
import { type PlayerControlsState, VideoPlayerControlsStateless } from './VideoPlayerControlsStateless';

type VideoPlayerControlsProps = {
  player: CbcVideoplayer | null;
  pausedOnReturnToVideo: MutableRefObject<boolean>;
};

export const VideoPlayerControls = ({ player, pausedOnReturnToVideo }: VideoPlayerControlsProps) => {
  const [isReady, setIsReady] = useState(false);
  const [hasVideoStarted, setHasVideoStarted] = useState(false);
  const [isRepeatable, setIsRepeatable] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [playTime, setPlayTime] = useState(0);
  const [hoveredPlayTime, setHoveredPlayTime] = useState(0);
  const [visibleTimeout, setVisibleTimeout] = useState<NodeJS.Timeout | null>(null);
  const [volumeSliderValue, setVolumeSliderValue] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [isPauseButton, setIsPauseButton] = useState<boolean>(true);
  const isVisibleRef = useRef(true);

  useEffect(() => {
    if (!player) {
      return;
    }

    setVolumeSliderValue(player.getVolume());

    import('@cbc/videoplayer').then((module) => {
      const CBCVideoplayer = module.default;

      CBCVideoplayer.onEvent(
        player.playerElementId,
        getPlayerEventsCallback(
          setIsRepeatable,
          setHasVideoStarted,
          setLoading,
          setPlayTime,
          setIsFullscreen,
          setIsReady,
          setIsPauseButton
        )
      );
    });
  }, [player]);

  if (!player) {
    return null;
  }

  const playerControlsState: PlayerControlsState = {
    player,
    isReady,
    setVisibleTimeout,
    visibleTimeout,
    isRepeatable,
    setIsRepeatable,
    playTime,
    hoveredPlayTime,
    setHoveredPlayTime,
    setVolumeSliderValue,
    volumeSliderValue,
    isFullscreen,
    hasVideoStarted,
    loading,
    pausedOnReturnToVideo,
    isVisibleRef,
    isPauseButton,
  };

  return <VideoPlayerControlsStateless {...playerControlsState} />;
};
