import { useEffect, useState } from 'react';

export const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(true);

  const updateVisibility = () => {
    if (document.visibilityState === 'hidden') {
      setIsVisible(false);
    } else if (document.visibilityState === 'visible') {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', updateVisibility);

    return () => document.removeEventListener('visibilitychange', updateVisibility);
  }, []);

  return isVisible;
};
