/**
 * Detects whether called server- or clientside
 */
export const isBrowser = () => typeof window !== 'undefined';
export const isServer = () => !isBrowser();

/**
 *
 * Detects whether a device is a mobile device depending on the viewport width
 */
export const isMobile = () => {
  if (isServer()) {
    return true;
  }

  return window.matchMedia(`(max-width: 767px)`).matches;
};
