import { type RefObject, useEffect } from 'react';
import CbcVideoplayer from '@cbc/videoplayer/src/cbcVideoplayer';
import { isMobile } from '../utils/detect';
import { useIntersectionObserver } from './useIntersectionObserver';
import { usePageVisibility } from './usePageVisibility';
import { useWindowFocus } from './useWindowFocus';

export const useTogglePreroll = (
  player: CbcVideoplayer | null,
  targetRef: RefObject<HTMLElement>,
  pausedOnReturnToVideo: React.MutableRefObject<boolean>
) => {
  const rootMarginDesktop = '-156px';
  const rootMarginMobile = '-96px';

  const entry = useIntersectionObserver(targetRef, {
    threshold: 0.5,
    rootMargin: isMobile() ? rootMarginMobile : rootMarginDesktop,
  });

  const isVisible = usePageVisibility();
  const isWindowFocused = useWindowFocus();

  useEffect(() => {
    const shouldPauseAd = (entry && !entry.isIntersecting) || !isVisible || !isWindowFocused;

    if (player?.isAd() && shouldPauseAd) {
      player?.adAdapter?.pauseAd?.();
    } else if (pausedOnReturnToVideo?.current === false) {
      player?.adAdapter?.resumeAd?.();
    }
  }, [entry, isVisible, isWindowFocused, player, pausedOnReturnToVideo]);
};
