import type { AreaItem, ArticleHeadFields, SectionDataItemFields } from '../types/cms';
import { getArticleHeadDataByAttribute, getSectionDataByAttributeValue } from './areaSearch';

const disableAds = (
  data?: Array<AreaItem>,
  articleField = 'is-ad-free' as keyof ArticleHeadFields,
  sectionField = 'no-ads' as keyof SectionDataItemFields
): boolean => {
  if (!data) {
    return false;
  }

  return (
    getArticleHeadDataByAttribute(articleField, data) === 'true' ||
    getSectionDataByAttributeValue(sectionField, data) === 'true'
  );
};

export const disableDisplayAds = (data?: Array<AreaItem>): boolean => {
  if (!data) {
    return false;
  }
  return getSectionDataByAttributeValue('no-ads', data) === 'true';
};

export const disableVideoAds = (data?: Array<AreaItem>): boolean => {
  return disableAds(data, 'is-video-ad-free', 'no-video-ads');
};
