const formatTime = (totalSeconds: number, extension?: string, leadingZero: boolean = true) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const ext = extension ? ` ${extension}` : '';

  const formattedMinutes = leadingZero ? String(minutes).padStart(2, '0') : String(minutes);

  return `${formattedMinutes}:${String(seconds).padStart(2, '0')}${ext}`;
};

export const formatSecondsToMinutes = (totalSeconds: number, extension?: string, leadingZero?: boolean) => {
  return formatTime(Math.floor(totalSeconds), extension, leadingZero);
};

export const formatMillisecondsToMinutes = (totalMilliseconds: number, extension?: string, leadingZero?: boolean) => {
  return formatTime(Math.floor(Math.floor(totalMilliseconds) / 1000), extension, leadingZero);
};
