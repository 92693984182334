import type { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import CbcVideoplayer from '@cbc/videoplayer/src/cbcVideoplayer';
import { ISSUER } from '@cbc/videoplayer/src/videoManager/types';
import type { PlayerControlsState } from './VideoPlayerControlsStateless';

const SEEK_SECONDS = 15;
const HIDE_AFTER_SECONDS = 3;

export interface PlayTime {
  type: string;
  timestamp: number;
  time: number;
}

export interface SeekEvent {
  type: string;
  issuer: string;
  position: number;
  seekTarget: number;
  timestamp: number;
}

interface VideoModeChangeEvent {
  from: string;
  legacy: boolean;
  timestamp: number;
  to: string;
  type: string;
}

export const getSeekBarId = (playerElementId: string) => `${playerElementId}-seekbar`;

const play = (state: PlayerControlsState) => {
  state.player.play('', 'ui-client' as ISSUER);
};

export const startContent = (state: PlayerControlsState) => {
  hideControlsAfterTimeout(state);
  play(state);
};

export const pause = (state: PlayerControlsState) => {
  state.player.pause('ui-client' as ISSUER);
};

const playOrPauseVideo = (state: PlayerControlsState) => {
  const { isRepeatable, setIsRepeatable, player, pausedOnReturnToVideo } = state;

  if (player.isAd()) {
    playOrPauseAd(state);
  } else if (isRepeatable) {
    setIsRepeatable(false);
    hideControlsAfterTimeout(state);
    player.rePlayVideo();
  } else if (player.isPlaying()) {
    pause(state);
  } else {
    if (pausedOnReturnToVideo) {
      pausedOnReturnToVideo.current = false;
    }
    hideControlsAfterTimeout(state);
    play(state);
  }
};

const playOrPauseAd = (state: PlayerControlsState) => {
  if (state.player.isPlaying()) {
    state.player.adAdapter.pauseAd();
  } else {
    state.player.adAdapter.resumeAd();
  }
};

/**
 * handleWrapperClick - opens ads, only when controls are already visible
 */
export const handleBackgroundClick = (state: PlayerControlsState) => {
  const { isVisibleRef, pausedOnReturnToVideo, player } = state;

  if (isVisibleRef.current) {
    pausedOnReturnToVideo.current = true;
    if (player.isAd()) {
      player.adAdapter.openClickThrough();
    } else {
      playOrPauseVideo(state);
    }
  } else {
    isVisibleRef.current = true;
    hideControlsAfterTimeout(state);
  }
};

export const handlePlayClick = (playerControlsState: PlayerControlsState) => {
  const { isVisibleRef, player } = playerControlsState;
  if (isVisibleRef.current || !player.isAd()) {
    playOrPauseVideo(playerControlsState);
  }
  showControls(playerControlsState);
};

export const muteOrUnmuteVideo = (player: CbcVideoplayer) => {
  if (isMuted(player)) {
    player.unmute('ui-client' as ISSUER);
  } else {
    player.mute('ui-client' as ISSUER);
  }
};

export const toggleFullscreen = (player: CbcVideoplayer, isFullscreen: boolean) => {
  if (isFullscreen) {
    player.exitFullscreen();
  } else {
    player.enterFullscreen();
  }
};

export const seekForward = (player: CbcVideoplayer, playTime: number) => {
  player.seek(playTime + SEEK_SECONDS, 'ui-client' as ISSUER);
};

export const seekBackward = (player: CbcVideoplayer, playTime: number) => {
  player.seek(playTime - SEEK_SECONDS, 'ui-client' as ISSUER);
};

export const seekByClick = (event: SyntheticEvent, player: CbcVideoplayer) => {
  const nativeEvent: PointerEvent = event.nativeEvent as PointerEvent;
  const target: HTMLDivElement = nativeEvent.target as HTMLDivElement;
  const parent: HTMLElement = target.parentElement as HTMLElement;
  const barWidth = target.id === getSeekBarId(player.playerElementId) ? target.offsetWidth : parent.offsetWidth;
  const clickedPercent = (nativeEvent.offsetX / barWidth) * 100;
  player.seek((player.getDuration() / 100) * clickedPercent, 'ui-client' as ISSUER);
};

export const seekBarHover = (
  event: SyntheticEvent,
  player: CbcVideoplayer,
  setHoveredPlayTime: Dispatch<SetStateAction<number>>
) => {
  const nativeEvent: PointerEvent = event.nativeEvent as PointerEvent;
  const target: HTMLDivElement = nativeEvent.target as HTMLDivElement;
  const parent: HTMLElement = target.parentElement as HTMLElement;
  const barWidth = target.id === getSeekBarId(player.playerElementId) ? target.offsetWidth : parent.offsetWidth;
  const hoveredPercent = (nativeEvent.offsetX / barWidth) * 100;
  const hoveredSeconds = (player.getDuration() / 100) * hoveredPercent;
  setHoveredPlayTime(hoveredSeconds);
};

const showControls = (state: PlayerControlsState) => {
  state.isVisibleRef.current = true;
};

export const isMuted = (player: CbcVideoplayer) =>
  (player.htmlWrapper.videoElement as HTMLVideoElement).muted || !player.getVolume();

export const mouseMove = (state: PlayerControlsState) => {
  showControls(state);

  hideControlsAfterTimeout(state);
};

const hideControlsAfterTimeout = (state: PlayerControlsState) => {
  const { visibleTimeout, isVisibleRef, setVisibleTimeout, player } = state;

  if (visibleTimeout) {
    clearTimeout(visibleTimeout);
  }

  setVisibleTimeout(
    setTimeout(() => {
      if (player.isPlaying()) {
        isVisibleRef.current = false;
      }
    }, HIDE_AFTER_SECONDS * 1000)
  );
};

export const keyUp = (event: SyntheticEvent, state: PlayerControlsState) => {
  const { player, playTime } = state;

  event.preventDefault();
  const keyboardEvent: KeyboardEvent = event as unknown as KeyboardEvent;

  if (keyboardEvent.code === 'ArrowRight') {
    seekForward(player, playTime);
  }

  if (keyboardEvent.code === 'ArrowLeft') {
    seekBackward(player, playTime);
  }

  if (keyboardEvent.code === 'Space') {
    playOrPauseVideo(state);
  }
};

export const changePlayerVolume = (
  event: SyntheticEvent,
  player: CbcVideoplayer,
  setVolumeSliderValue: Dispatch<SetStateAction<number>>
) => {
  const volume = (event.target as HTMLInputElement).valueAsNumber;
  setVolumeSliderValue(volume);
  player.setVolume(volume);
};

export const getPlayerEventsCallback =
  (
    setIsRepeatable: Dispatch<SetStateAction<boolean>>,
    setHasVideoStarted: Dispatch<SetStateAction<boolean>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setPlayTime: Dispatch<SetStateAction<number>>,
    setIsFullscreen: Dispatch<SetStateAction<boolean>>,
    setIsReady: Dispatch<SetStateAction<boolean>>,
    setIsPauseButton: Dispatch<SetStateAction<boolean>>
  ) =>
  (e: { type: string }) => {
    if (e.type === 'onPlaying') {
      setHasVideoStarted(true);
      setLoading(false);
      setIsRepeatable(false);
      setIsPauseButton(true);
    }

    if (e.type === 'onPaused' || e.type === 'onContentEnd') {
      setIsPauseButton(false);
    }

    if (e.type === 'onTimeChanged') {
      setPlayTime((e as PlayTime).time);
    }

    if (e.type === 'onSeek') {
      setPlayTime((e as SeekEvent).seekTarget);
    }

    if (e.type === 'onVideoModeChanged') {
      const event = e as VideoModeChangeEvent;
      if (event.to === 'fullscreen') {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    }

    if (e.type === 'onVideoLoad') {
      setIsReady(true);
      setLoading(true);
    }

    if (e.type === 'onSeek' || e.type === 'onPlay') {
      setLoading(true);
    }

    if (e.type === 'onReady' || e.type === 'onSeeked') {
      setLoading(false);
    }

    if (e.type === 'onContentEnd') {
      setIsRepeatable(true);
    }

    adEvents(setLoading)(e);
  };

const adEvents = (setLoading: Dispatch<SetStateAction<boolean>>) => (e: { type: string }) => {
  if (e.type === 'onAdSlotStart') {
    setLoading(false);
  }
};
