import type { PropsWithClassName } from '../../../types/react-props';
import Image, { type ApiImageProps } from '../Image/Image';

type VideoPlayerPosterProps = PropsWithClassName<{
  readonly image: ApiImageProps;
  readonly altText: string;
}>;

const VideoPlayerPoster = ({ image, altText, className }: VideoPlayerPosterProps) =>
  image && <Image className={className} image={image} alt={altText} width={1200} priority />;

export default VideoPlayerPoster;
