'use client';
import { useContext, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { AppContext, type AnalyticsDataArticleProps } from '../../../context/AppContext';
import type { VideoProps } from '../../UI/VideoPlayer/VideoPlayerDefault';

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
  }
}

type GtmContentFields = {
  main_section?: string;
  sub_sections?: { [key: string]: string };
  article_infos?: AnalyticsDataArticleProps;
};

export function VideoContentStarted(video: VideoProps): void {
  if (!window?.dataLayer) {
    console.warn('DataLayer is not available.');
    return;
  }

  if (!video?.sourceId || !video?.fields?.title) {
    console.error('Invalid video object provided.', video);
    return;
  }

  window.dataLayer.push({
    event: 'video_content_started',
    media: {
      id: video.sourceId,
      title: video.fields.title,
    },
  });
}

export const GTMID = 'GTM-5MXC2MF';

const SectionMapper = (sectionPath: string, isFrontpage: boolean = false): GtmContentFields => {
  const sections = sectionPath.split('/');
  const subSections = sections.slice(1);
  const formattedSubsections: { [key: string]: string } = {};
  subSections.forEach((section, index) => {
    formattedSubsections[`sub_section_${index + 1}`] = section;
  });

  return {
    main_section: isFrontpage ? 'newshome' : sections[0],
    sub_sections: formattedSubsections,
  };
};

export const GoogleTagManager = () => {
  const {
    pageType,
    pageTitle,
    analyticsData: { ivwCode, homeSectionPath, articleFields },
    pageMetaData: { adZone, keywords },
  } = useContext(AppContext);
  const pathname = usePathname();

  let fields: GtmContentFields = {};

  if (!homeSectionPath && pageTitle === 'frontpage') {
    fields.main_section = 'newshome';
  }

  if (pageType === 'page' && homeSectionPath) {
    fields = SectionMapper(homeSectionPath, pageTitle === 'frontpage');
  }

  if (pageType === 'article' && articleFields && homeSectionPath) {
    fields = SectionMapper(homeSectionPath);
    fields.article_infos = articleFields;
  }

  useEffect(() => {
    const agofCode = ['dbrsowf_rtl', fields.main_section, ...Object.values(fields.sub_sections ?? [])]
      // filter out sections "show" and "sendungen"
      .filter((value) => value !== 'show' && value !== 'sendungen')
      .join('');

    window.dataLayer.push({
      content: null,
    });
    window.dataLayer.push({
      event: 'pageview',
      content: {
        agof_code: agofCode,
        ivw_code: ivwCode,
        main_section: fields.main_section ?? '',
        ad_zone: adZone,
        ad_keywords: keywords,
        ...fields.sub_sections,
        ...fields.article_infos,
      },
      tech: {
        development_version_id: process.env.APP_VERSION,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Script
        id="gtm-script-content"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTMID}');`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTMID}" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager"></iframe>`,
        }}
      />
    </>
  );
};
