export const toKebabCase = (source: string): string =>
  source
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

export const slugify = (text: string) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

export const sanitizeHtml = (dirty: string): string => {
  return decodeURI(encodeURI(dirty).replaceAll('%E2%80%9D', '%22'));
};
